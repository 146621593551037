<template>
  <div>
    <PageHeader
      :title="`${role}s`"
      :description="`Gestion des ${role}s`"
      :button="{
        icon: 'fas fa-plus-circle',
        text: `Ajouter un ${role}`,
        action: create
      }"
      :buttonBis="{
        icon: 'fas fa-file-import',
        text: `Importer des utilisateurs`,
        action: openImportModal
      }"
    />
    <UsersSubmenu />

    <PageContent>
      <div class="relative">
        <MVPTable
          :key="$route.params.role"
          :grid-data="gridData"
          :options="gridOptions"
          @gridReady="onGridReady"
          @selectionChanged="selectedUsers = $event"
          @rowDoubleClicked="openModal"
        />
        <button
          v-if="$route.params.role === 'user'"
          class="absolute -bottom-12 right-2 bg-blue-500 hover:bg-blue-600 text-white py-2.5 px-6 rounded-md text-sm ring-1 ring-green-500"
          @click="extractSelection"
          title="Extraire la liste filtrée telle qu'elle est"
        >
          <i class="fas fa-file-export"></i> Extraire la liste
        </button>
      </div>
      <button
        v-if="selectedUsers.length > 0"
        class="button is-danger"
        @click="deleteUsers"
      >
        <i class="fas fa-trash"></i>
      </button>
    </PageContent>

    <t-modal
      name="user-form"
      variant="contentForm"
      @before-open="modalOnBeforeOpen"
    >
      <MVPUsersForm
        v-if="currentUser"
        :user="currentUser"
        :userKey="currentUser.uuid"
        :role="$route.params.role"
        :groups="groups"
        :domains="domains"
        @refresh="loadUsers"
      />
    </t-modal>
    <t-modal
      name="user-import-modal"
      variant="smallModal"
    >
      <UserCsvImportModal />
    </t-modal>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'
import MVPTable from '@/components/agGrid/MVPTable.vue'
import UsersSubmenu from './UsersSubmenu.vue'
import CreateUser from './Modals/Create'
import MVPUsersForm from './Form.vue'
import UserCsvImportModal from './Import.vue'

export default {
  name: 'app-users-list',
  components: {
    PageHeader,
    PageContent,
    UsersSubmenu,
    MVPTable,
    MVPUsersForm,
    UserCsvImportModal
  },
  computed: {
    domain () {
      return this.$store.getters.domain
    },
    role () {
      return this.roles[this.$route.params.role]
    },
    isServerSide () {
      return this.$route.params.role === 'user'
    }
  },
  data () {
    return {
      loading: false,
      storageUrl: process.env.VUE_APP_STORAGE_URL,
      users: [],
      groups: [],
      domains: [],
      currentUser: null,
      selectedUsers: [],
      roles: {
        user: 'Utilisateur',
        'community-manager': 'Community manager',
        operator: 'Observateur',
        director: 'Administrateur',
        'account-manager': 'Account manager'
      },
      gridData: null,
      gridOptions: {},
      currentSortModel: [],
      currentFilterModel: {}
    }
  },
  created () {
    this.loadGroups()
    this.loadDomains()
    this.initializeGridOptions()
  },
  watch: {
    role (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.loadGroups()
        this.loadDomains()
      }
    },
    domain (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.loadGroups()
        this.loadDomains()
      }
    },
    '$route.params.role': {
      handler (newRole, oldRole) {
        if (newRole !== oldRole) {
          this.initializeGridOptions()
          if (this.gridApi) {
            if (this.isServerSide) {
              const datasource = this.createDataSource()
              this.gridApi.setServerSideDatasource(datasource)
            } else {
              this.loadClientSideData()
            }
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    initializeGridOptions () {
      const commonGridOptions = {
        masterDetail: true,
        detailCellRenderer: 'ListRowDetail',
        detailRowAutoHeight: true,
        rowSelection: 'multiple',
        rowMultiSelectWithClick: true,
        suppressContextMenu: true,
        onSortChanged: params => {
          this.currentSortModel = (typeof params.api.getSortModel === 'function')
            ? params.api.getSortModel()
            : []
        },
        onFilterChanged: params => {
          this.currentFilterModel = (typeof params.api.getFilterModel === 'function')
            ? params.api.getFilterModel()
            : {}
        },
        columnDefs: [
          {
            headerName: 'Nom',
            field: 'lastname',
            filter: 'agTextColumnFilter',
            checkboxSelection: true,
            sortable: true
          },
          {
            headerName: 'Prénom',
            field: 'firstname',
            filter: 'agTextColumnFilter',
            sortable: true
          },
          {
            headerName: 'Adresse e-mail',
            field: 'email',
            filter: 'agTextColumnFilter',
            sortable: true
          },
          {
            headerName: 'Groupes',
            field: 'groups',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
              const groups = params.data.groups || []
              return groups.map((group) => group.label).join(', ')
            }
          },
          {
            headerName: 'Date inscription',
            field: 'email_verified_at',
            type: ['dateColumnFilterable'],
            cellRenderer: 'DateCellRenderer',
            sortable: true
          },
          {
            headerName: 'Téléchargé',
            headerClass: 'ag-center-aligned-header',
            field: 'is_verified',
            sortable: true,
            cellRenderer: 'BooleanCellRenderer',
            width: 150,
            cellStyle: {
              textAlign: 'center'
            },
            filter: 'agSetColumnFilter',
            filterParams: {
              cellRenderer: 'BooleanFilterCellRenderer',
              buttons: ['reset'],
              values: [0, 1]
            }
          }
        ]
      }

      if (this.isServerSide) {
        this.gridOptions = {
          ...commonGridOptions,
          rowModelType: 'serverSide',
          suppressServerSideInfiniteScroll: false,
          cacheBlockSize: 100,
          maxBlocksInCache: 10,
          statusBar: {
            statusPanels: [
              {
                statusPanel: 'TotalRowCountStatusBar',
                align: 'left'
              }
            ]
          },
          context: {
            componentParent: this
          }
        }
      } else {
        this.gridOptions = {
          ...commonGridOptions,
          rowModelType: 'clientSide'
        }
      }
    },
    goToUser ({ data }) {
      this.$router.push({ path: `/app/user/${data.uuid}` })
    },
    openImportModal () {
      this.$modal.show('user-import-modal')
    },
    loadGroups () {
      http.get('/groups').then((res) => {
        this.groups = res.data
      })
    },
    async deleteUsers () {
      for (let user of this.selectedUsers) {
        try {
          await http.delete(`/user/${user.uuid}`)
          this.$buefy.notification.open({
            duration: 4000,
            message: `L'utilisateur ${user.email} a été supprimé avec succès !`,
            position: 'is-bottom-left',
            type: 'is-success',
            hasIcon: true
          })
        } catch (err) {
          console.log(err)
          this.$buefy.notification.open({
            duration: 3000,
            message: err.response.data.message,
            position: 'is-bottom-left',
            type: 'is-warning',
            hasIcon: true,
            icon: 'server'
          })
        }
      }
      if (this.isServerSide) {
        this.gridApi.refreshServerSideStore({ purge: true })
      } else {
        this.loadClientSideData()
      }
    },
    create () {
      this.$buefy.modal.open({
        parent: this,
        component: CreateUser,
        hasModalCard: true,
        destroyOnHide: false,
        ariaRole: 'dialog',
        ariaModal: true,
        props: {
          role: this.$route.params.role
        },
        events: {
          created: (created) => {
            if (!this.isServerSide) {
              this.users.push(created)
              this.gridApi.applyTransaction({ add: [created] })
            }
            this.$buefy.notification.open({
              duration: 4000,
              message: `L'utilisateur ${created.email} a été créé avec succès !`,
              position: 'is-bottom-left',
              type: 'is-success',
              hasIcon: true
            })
          }
        }
      })
    },
    modalOnBeforeOpen ({ params, cancel }) {
      console.log('open modal', params)
    },
    async openModal ({ data }) {
      if (!this.domains || this.domains.length === 0) {
        await this.loadDomains()
      }
      this.currentUser = data ?? {}
      console.log('open modal', data)
      this.$modal.show('user-form', data)
    },
    loadDomains () {
      return http.get('/domain').then((res) => {
        this.domains = res.data
      })
    },
    createDataSource () {
      return {
        getRows: (params) => {
          const { startRow, endRow, sortModel, filterModel } = params.request

          const queryParams = {
            startRow,
            endRow,
            sortModel: JSON.stringify(sortModel),
            filterModel: JSON.stringify(filterModel)
          }

          http
            .get(`/${this.$route.params.role}`, { params: queryParams })
            .then((res) => {
              const rows = res.data.rows
              const totalRows = res.data.totalCount

              this.totalRowCount = totalRows
              params.api.dispatchEvent({ type: 'totalRowCountUpdated' })

              params.successCallback(rows, totalRows)
            })
            .catch((err) => {
              console.error(err)
              params.failCallback()
            })
        }
      }
    },
    loadClientSideData () {
      this.loading = true
      http
        .get(`/${this.$route.params.role}`)
        .then((res) => {
          this.loading = false
          const rowData = res.data
          this.gridApi.setRowData(rowData)
        })
        .catch((err) => {
          console.error(err)
          this.loading = false
        })
    },
    onGridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi

      if (this.isServerSide) {
        const datasource = this.createDataSource()
        params.api.setServerSideDatasource(datasource)
      } else {
        this.loadClientSideData()
      }
    },
    extractSelection () {
      const queryParams = {
        sortModel: JSON.stringify(this.currentSortModel || []),
        filterModel: JSON.stringify(this.currentFilterModel || {})
      }

      http.get(`/${this.$route.params.role}/export`, {
        params: queryParams,
        responseType: 'blob'
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.$route.params.role}-extracted.csv`)
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          console.error('Export failed', err)
        })
    },
    downloadCSV (csvData, fileName) {
      const blob = new Blob([csvData], { type: 'text/csvcharset=utf-8' })
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', fileName)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>
